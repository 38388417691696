import React from 'react'

import ErrorBoundary from '../shared/ErrorBoundary'
import CoverPage from './sf/CoverPage'
import IndexPage from './sf/IndexPage'
import ExecutiveSummary from './sf/ExecutiveSummary'
import RelevantFunds from './sf/RelevantFunds'
import PerformanceAnalytics from './sf/PerformanceAnalytics'
import OtherRelevantFunds from './sf/OtherRelevantFunds'
import RFPerformance from './sf/RFPerformance'
import Appendix from './sf/Appendix'
// import { Disclosures } from './sf/Disclosures'
import { ConclusionWrapper } from './sf/Conclusion'
import moment from 'moment'
import {
  // GlossaryPage1SFM,
  // GlossaryPage2,
  // GlossaryPage3,
  // GlossaryPage4,
  GlossaryPage5
} from './sf/Glossary'
// import SearchPage from './sf/Search'
// import ComparePage from './sf/Compare'
// import CombinePage from './sf/Combine'
import FundsPage from './sf/Funds'

import { fetchData } from './sf/Data'

import ActReportContext, { ActReportContextProvider } from './ActReportContext'
import { getAllPages } from '../../layouts/WebDashboard/SFM/PrintPreviewModal/PrintPreviewModal'
// import { haveHTMLData } from 'Utils';

// const ReportOptions = require('./reports-options.json');

const ALL_PAGES = getAllPages()

export default class SfReportV2 extends React.PureComponent {
  state = {}

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const rid = query.get('id')
    if (!rid) return

    fetchData(rid).then(data => {
      if (data && data.compare && data.compare.length && data.compare[0]) {
        //fail-safe
        data.printSelection =
          !data.printSelection || data.printSelection === []
            ? ALL_PAGES
            : data.printSelection
        // console.log({ data })
        // console.log(!data.printSelection.includes('rel-funds'))
        data.showOtherRelevantFunds = !data.printSelection.includes('rel-funds')
          ? data.compare[0].attrs.length > 9
          : data.compare[0].attrs.length > 7
      }
      //['ex-sum','rel-funds',"sel-criteria", "perf-analytics-tradeoff", "perf-analytics-up-down-capture", "perf-analytics-cor-matrix", "perf-analytics-factor-exposure", "high-rel-funds", "conclusion", "other-rel-funds", "fund-fact-sheet"]
      // data.printSelection=['ex-sum','rel-funds','high-rel-funds','conclusion',]
      let createdDate = moment(
        new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
      ).format('MM/DD/YYYY')
      if (data && data.selectedFunds) {
        const { selectedFunds } = data
        const selectorFeedDate = selectedFunds.find(k => k.feed_date)
        createdDate =
          selectorFeedDate && selectorFeedDate.feed_date
            ? moment(selectorFeedDate.feed_date).format('MM/DD/YYYY')
            : moment(
                new Date().toLocaleString('en-US', {
                  timeZone: 'America/New_York'
                })
              ).format('MM/DD/YYYY')
        data.createdDate = createdDate
        data.selectedFunds.forEach(e => {
          if (
            e.asset_alloc &&
            Object.keys(e.asset_alloc) &&
            typeof e.asset_alloc.etf_mf_security_id !== 'undefined'
          ) {
            delete e.asset_alloc.etf_mf_security_id
          }
          if (
            e._viewData &&
            Object.keys(e._viewData) &&
            typeof e._viewData.etf_mf_security_id !== 'undefined'
          ) {
            delete e._viewData.etf_mf_security_id
          }
        })
      }
      // console.log('first --- > data', data);
      this.setState({ data })
    })

    // this.setState({ data: ReportOptions })

    document.body.classList.add('shared-report')
    document.body.classList.add('act-report')
  }

  componentWillUnmount() {
    document.body.classList.remove('shared-report')
    document.body.classList.remove('act-report')
  }

  render() {
    if (!this.state.data) return null
    return (
      <ErrorBoundary>
        <ActReportContextProvider value={this.state.data}>
          <Content logo={this.state.data.logo} />
        </ActReportContextProvider>
      </ErrorBoundary>
    )
  }
}

class Content extends React.PureComponent {
  static contextType = ActReportContext

  render() {
    // use this.context for data
    // console.log(this.context, 'dfsfd');
    const {
      selectedFunds,
      search,
      flags,
      allFunds,
      disclosures,
      otherFunds,
      compare,
      showOtherRelevantFunds,
      queryFunds,
      logo,
      printSelection
    } = this.context
    const n = selectedFunds.length
    const props = {
      total: 1 + (n < 5 ? 3 : n < 7 ? 4 : 5) + n,
      date: search.date,
      allFunds,
      logo
    }

    const renderAppendix = queryFunds.map(data => {
      return (
        <Appendix
          data={data}
          {...props}
          pageName={'appendix,other-rel-funds'}
        />
      )
    })
    let selectorFeedDate = selectedFunds.find(k => k.feed_date)
    const createdDate =
      selectorFeedDate && selectorFeedDate.feed_date
        ? moment(selectorFeedDate.feed_date).format('MM/DD/YYYY')
        : moment(
            new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
          ).format('MM/DD/YYYY')

    return (
      <>
        <CoverPage
          logo={this.props.logo}
          disclosures={disclosures}
          createdDate={createdDate}
        />
        <div className="container h-100">
          <IndexPage {...props} createdDate={createdDate} />
          {/*exe-sum full page*/}
          <ExecutiveSummary {...props} createdDate={createdDate} />
          {/*small rel-funds, sel-criteria might get on to next page*/}
          {(printSelection.includes('rel-funds') ||
            printSelection.includes('sel-criteria')) && (
            <RelevantFunds
              {...props}
              createdDate={createdDate}
              pageName={`${
                printSelection.includes('rel-funds') ? 'rel-funds' : ''
              }${
                printSelection.includes('sel-criteria') ? ',sel-criteria' : ''
              }`}
            />
          )}
          {/*remaining sel-criteria*/}
          {printSelection.includes('sel-criteria') &&
            showOtherRelevantFunds && (
              <OtherRelevantFunds
                {...props}
                createdDate={createdDate}
                pageName={'sel-criteria'}
              />
            )}
          {/*4 perf analytics pages*/}
          <PerformanceAnalytics createdDate={createdDate} />
          {/*high rel funds and conclusion */}
          {printSelection.includes('high-rel-funds') && (
            <RFPerformance createdDate={createdDate} {...props} />
          )}
          {/*conclusion if the high rel fund not selected*/}
          {!printSelection.includes('high-rel-funds') &&
            printSelection.includes('conclusion') && (
              <ConclusionWrapper
                createdDate={createdDate}
                pageName={'conclusion'}
              />
            )}
          {/*appendix other rel-funds*/}
          {otherFunds.length > 0 &&
            printSelection.includes('other-rel-funds') &&
            renderAppendix}
          {/*appendix fund fact sheet*/}
          {printSelection.includes('fund-fact-sheet') && (
            <FundsPage {...props} createdDate={createdDate} />
          )}

          {<GlossaryPage5 {...props} />}
          {/* <GlossaryPage1SFM {...props} />
        <GlossaryPage2 {...props} /> */}
          {/*disclosures section*/}
          {/* {disclosures && disclosures.length > 0 && haveHTMLData(disclosures) && <Disclosures disclosure={disclosures} />} */}
          {/* <GlossaryPage3 {...props} />
        <GlossaryPage4 {...props} /> */}
        </div>
      </>
    )
  }
}

/***********************************************************
************************************************************

NOTE: For adding pages and page numbers dynamically to the
index page, add a prop with the name 'pageName' to the
components wrapped around 'withHeaderFooter' layer (those
without the property 'showHeaderFooter' set to 'false').

Create a corresponding mapping for the pageName in the
'shared/Utils.js' with the page name & other properties.

************************************************************
***********************************************************/
