import React from 'react';
import {Cell, Pie, PieChart, Tooltip, Legend} from "recharts";
import NotApplicable from 'components/NotApplicable';
// import { Row, Col } from 'reactstrap';

export const SimplePieChart = (props) => {
  console.log("SimplePieChart -> ", props)
  const { data, colors, decimal, chartName, centerDisplayText, centerValueText } = props;

  if (
    !data ||
    !data ||
    (data && !data.length)
  ) {
    return <NotApplicable />
  };

  const formatter = (value) => value.toFixed(decimal) + '%';

  const primaryTextStyle = (chartName === 'Top Holdings' || chartName === 'Sectors') ?  {
    // top: '39%',
    fontSize: props.smallScreen ? 8 : 11,
    fontWeight: 'bold',
    zIndex: '5',
  }: {};

  return (
    <div className="simplePieChartWrapper">
      {(chartName === 'Top Holdings' || chartName === 'Sectors') && (centerDisplayText !== '' && centerValueText !== '')
      && (
        <div className="primaryTextStyle" style={primaryTextStyle}
        >
          {centerValueText}
          <div className="highlightTooltip">{centerDisplayText}: {centerValueText}</div>
        </div>
      )
      }
      <PieChart width={props.width || 90} height={props.height || 90}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={props.width ? (props.width === 200 ? 98 : 37) : 42}
          innerRadius={props.width ? (props.width === 200 ? 60 : 17) : 20}
          isAnimationActive={false}
          fill="#8884d8"
        >
          {props.data.map((v, i) => (
            <Cell
              key={i}
              fill={v.color || colors[i % colors.length]}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={formatter}
          itemStyle={{fontSize: '12px', padding: 0}}
        />
      </PieChart>

      <div className="w-100" style={{marginTop: '10px'}}>
        <table style={{margin: '10px auto 0px'}}>
          <tbody>
          {props.data.map((v, i) => (
            <tr className="aa-chart-legend">
              <td>
                {/*<span style={{padding:'2px', height: '100%', marginRight: '5px', background:v.color || colors[i % colors.length]}}></span>*/}
                <p className="aa-chart-legend-name" style={{borderLeft: `4px solid ${v.color || colors[i % colors.length]}`}}>{v.name}</p>
              </td>
              <td style={{paddingLeft: '20px'}}>
                <p className="aa-chart-legend-value" style={{color:v.color || colors[i % colors.length]}}>{v.value.toFixed(decimal)}%</p>
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        {/*<Row>
            <Col xs="6" className="text-right">
              <span className="h-100 float-right" style={{display: 'flex', paddingBottom: '10px'}}>
                <span style={{width:'5px', height: '100%', display: 'inline-block', float: 'left', marginRight: '5px', background:'blue'}}></span>
                <span className="float-left">Bonds</span>
              </span>
            </Col>
            <Col xs="6">85.4%</Col>
        </Row>*/}
      </div>

    </div>
  );
};

export default SimplePieChart;
