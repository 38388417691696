import React from 'react';
import axios from 'axios';
import moment from 'moment';
import * as fundsAPI from 'apis/funds';

import { createAPIInstance } from 'apis/api';
import { fetchCharts, fetchCategories } from 'apis/funds';
import { uniqValues, getNestDefault, isViewPresentInChartTypes, arrayToHash } from 'Utils';
import { cn, SectorsAttrs, CN, DateRange } from 'DataSet';
// import { executeDataMapping } from '../../utils';
import { filteredData } from './utils';
import { getNest } from 'Utils';

/* Do not need complexity of redux store for data as page is loaded/rendered just once.
   Page wont be interative and data won't be modified.
   So using context as global store */
export const DataContext = React.createContext({});

const ReportOptions = require('./dump.json');

const isDynamicChart = (view, query_var) => {
  if (!query_var.Static && query_var.chart_type === 'odometer') {
    return true;
  }
  return false;
}

function fetchFundsByTickers(payload) {
  return createAPIInstance()
    .get('/batch-search', { params: payload }, {
      headers: {
        Authorization: null
      }
    })
    .then(response => response.data)
}

function fetchRealTimePrices(payload) {
  return createAPIInstance()
    .get('/realtime-prices', { params: payload })
    .then(response => response.data)
}


const getFundsUpdatedPrice = (res, funds) => {
  // console.log(res["funds"]);
  const priceData = arrayToHash(res["funds"], CN['Ticker']);
  funds.forEach(e => {
    if(e[CN['Vehicle']] == 'ETF' || e[CN['Vehicle']] == 'ETN')
      e[CN['Nav Price']] = getNestDefault([cn(e, 'Ticker'), 'price'], priceData, cn(e, 'Nav Price'));
  });
  return funds;
}

export const getDataByTickers = async (request) => {
  const funds = await fetchFundsByTickers({ q: request.tickers, query: request.prtQuery });
  // console.log(funds);

  return funds;
}

export const fetchReportData = async (requestQuery) => {
  return createAPIInstance()
    .get('/user/get-compare-reports', {params: {id: requestQuery}})
    .then(response => response.data)
}

export const fetchData = async (funds, requestQuery) => {

    const { Highlights, date_range } = funds;
    const FundsDateRange = { start: moment(date_range.start), end: moment(date_range.end) };

    const ALL_FUNDS_TICKER = uniqValues(funds.funds, 'ticker');

    let [ fundsPrice ] = await Promise.all([
      fetchRealTimePrices({ tickers: ALL_FUNDS_TICKER.join(',') }),
    ]);

    let [ catData ] = await Promise.all([
      fetchCategories({ tickers: ALL_FUNDS_TICKER.join(',') }),
    ]);

    let allFunds = funds.funds;

    if (fundsPrice) {
      const updatedFundsPriceData = getFundsUpdatedPrice(fundsPrice, allFunds);
      if (updatedFundsPriceData) {
        allFunds = updatedFundsPriceData;
      }
    }

    let queryVar = funds.query_var.filter(e => {
      if (e.active && e.view_name && e.view_name != "") {
        if(e.view_name != 'Highlight'){
          return e;
        }
      }
    })[0];

    allFunds.forEach(e => {

      if(!queryVar || queryVar.length === 0)  {
        e._viewChartType = "odometer"
        e._viewData = funds['Assets'][e.ticker];
        // render assets odometer by default
        queryVar = {
          active: true,
          Static: true,
          view_name: 'Assets',
          Secondary_view_name: '',
          display_view_name : 'Assets',
          chart_type: 'odometer',
        }
      } else {
        // const queryvar = queryVar[0];
        const staticVal = queryVar.Static;
        const viewName = queryVar.view_name;
        e._viewName = viewName;
        // e._queryVar = queryVar;
        if (!staticVal) {
          if (!isViewPresentInChartTypes(viewName) && isDynamicChart(viewName, queryVar)) {
            e._viewChartType = "dynamicGaugeChart";
            e._viewData = funds[viewName][e.ticker];
          } else if(viewName === "Themes") {
            e._viewChartType = "carousel";
            e._viewData = funds[viewName][e.ticker];
          } else {
            e._viewChartType = queryVar.chart_type;
            e._viewData = funds[viewName][e.ticker];
          }

        } else {
          if(viewName === "Regions") {
            e._viewChartType = "burst_chart";
            e._viewData = funds[viewName][e.ticker];
          } else if (viewName === "Top Holdings" || viewName === "Asset Allocation") {
            e._viewChartType = "pie_chart";
            e._viewData = viewName === "Top Holdings" ? funds[viewName]['funds'].find(t => t.ticker === e.ticker) : funds[viewName].find(t => t.ticker === e.ticker);
          } else {
            e._viewChartType = queryVar.chart_type;
            e._viewData = funds[viewName][e.ticker];
          }
        }
      }

      let performance = [], noPerfVal = false;
      const perfFees = e.Fee_Risk_Return || [];
      Object.keys(perfFees).map((data, i) => {
        const year = data.match(/\d+/) ? data.match(/\d+/)[0] : '';
        if (year !== '') {
          if (!perfFees[data] || (perfFees[data] && (perfFees[data] === '' || perfFees[data] === null || perfFees[data] === 'NaN'))) noPerfVal = true;
          if (data.includes("Category Annualized")) {
            const index = performance.findIndex(x => x.year === year);
            if (index === 0) {
              performance[index].Category = perfFees[data];
            } else if (!index || index < 0) {
              performance.push({
                year,
                name: `${year} year`,
                Category: perfFees[data],
              })
            } else {
              performance[index].Category = perfFees[data];
            }
          } else if (data.includes("Annualized")) {
            const index = performance.findIndex(x => x.year === year);
            if (index === 0) {
              performance[index].Fund = perfFees[data];
            } else if (!index || index < 0) {
              performance.push({
                year,
                name: `${year} year`,
                Fund: perfFees[data],
              })
            } else {
              performance[index].Fund = perfFees[data];
            }
          } else return null;
        } else return null;
      })

      performance.push({
        name: 'Since Inception',
        Fund: perfFees['Annualized Average'],
        Category: perfFees['Category Annualized Average']
      })

    });

    const newUpdatedFundsData = filteredData(allFunds, FundsDateRange);

    const searchQuery = requestQuery;

    const requestPayload = {
      q: searchQuery,
      universe: 'all',
    };

    catData["categories"].forEach(e => {
      let r = getNest(['returns', 0], e);
      if (r){
        e._start = r['d'];
        e._end = e.returns[e.returns.length-1]['d'];
      }
      e.returns.forEach((item,index) => {
        e.returns[index].v = item.v/100;
      });
    });

    return {
      categories: catData.categories || [],
      funds: newUpdatedFundsData || [],
      highlightText: Highlights || {
        feesHighLightEnable: false,
        returnHighLightEnable: false,
        riskHighLightEnable: false,
      },
      range: FundsDateRange,
      dateRangeSelected: FundsDateRange,
      filterAttrData: {},
      relevantViewNames: [],
      chartData: [],
      ticks: ALL_FUNDS_TICKER,
      itemCardView: "",
      queryVar,
      allFunds,
      search: requestQuery,
    };

}

// exclude all funds which are not in search result
const validateOptions = (options, ticks) => ({
  ...options,
  selectedFunds: options.selectedFunds.filter(e => ticks.includes(e)),
  compare: options.compare
             .map(c => ({...c, funds: c.funds.filter(e => ticks.includes(e.ticker))}))
             .filter(c => c.funds.length > 0),
  combine: {
    ...options.combine,
    all: {
      ...options.combine.all,
      funds: options.combine.all.funds.filter(e => ticks.includes(e.ticker))
    }
  }
})

const flags = (options, newFlags) => {
  return {
    selectFunds: options.selectedFunds.length > 0,
    otherFunds: true,
    compare: options.compare.length > 0,
    otherCompare: options.compare.length > 1,
    combine: getNestDefault(['combine', 'all', 'funds', 'length'], options, 0) > 0,
    best: !!options.combine.all,
    ...newFlags,
  }
}

const getSectorData = item => {
  let out = [];
  SectorsAttrs.forEach(e => {
    let v = cn(item, e);
    if (v !== undefined && v > 0.0)
      out.push({ n: e, v });
  })
  return out;
}
