import React, { useContext } from 'react';
// import { Row, Col, Table } from 'reactstrap';

import MdlEnhancerContext from '../Context/MdlEnhancerContext';

import PfCardWrapper from '../Components/PfCardWrapper';
import { withHeaderFooter } from '../Shared/HeaderFooter';

const SelectedReplacementPage = withHeaderFooter((props) => {
  // console.log(useContext(MdlEnhancerContext));
  const {
    modelEnhancement: {
      current,
      enhanced,
    },
    query_english,
    queryEnglish,
  } = useContext(MdlEnhancerContext);
  const holdingsCutOff = 5;

  current._originalQueryVar = (enhanced && enhanced.data && enhanced.data._originalQueryVar) ? enhanced.data._originalQueryVar : [];
  current._defaultFRR = (enhanced && enhanced.data && enhanced.data._defaultFRR) ? enhanced.data._defaultFRR : [];

  // console.log('query_english ==>', query_english);
  // console.log(props);
  const queryLike = queryEnglish || query_english;
  return (
    <React.Fragment>
      <div className="content">
        <div className="pr-v2-header">
          <h3>We attempted to enhance your portfolio through {queryLike ? `'${queryLike}'` : 'your selection'}.</h3>
        </div>
        <div className="mdl-enh-rep-sub-header">
          <h4>The current portfolio and the suggested replacement is presented below</h4>
        </div>
        <div className="sel-rep-section">
          <div className="sel-rep-wrapper">
            <PfCardWrapper
              query={query_english}
              data={current}
              type={'current'}
              divider={30} // Space between cards in pixels
              pfTitle={'Current portfolio'}
              holdingsTitle={`Top ${holdingsCutOff} Holdings`}
              holdingsCutOff={holdingsCutOff}
            />
          </div>
          <div className="sel-rep-wrapper">
            <PfCardWrapper
              query={query_english}
              data={enhanced}
              type={'enhanced'}
              divider={30} // Space between cards in pixels
              pfTitle={'Suggested Replacement'}
              holdingsTitle={`Top ${holdingsCutOff} Holdings`}
              holdingsCutOff={holdingsCutOff}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
});
export default SelectedReplacementPage;
