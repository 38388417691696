import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { logout } from 'actions/auth';

import ErrorBoundary from 'components/shared/ErrorBoundary';
// import { getWindowScreen } from 'layouts/WebDashboard/SearchResults/utils';
import Content from './Content';
import { fetchReportData } from './Data';
import { PortfolioContextProvider } from './PortfolioContext'

import { setGlobalState } from 'actions/global';

// let ReportData = require('./dump.json');
// let ReportOptions = [];
class PortfolioReportV2 extends Component {
  constructor (props) {
    super(props);
    this.state = {
      id: '',
      reportData: '',
      // reportData: JSON.parse(ReportData[0].json)
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const rid = query.get('id')
    if (!rid) return;
    this.setState({ id: rid })
    fetchReportData(rid).then(reportData => reportData && this.setState({ reportData: { ...JSON.parse(reportData[0].json), reportCreatedAt: reportData[0].created_at} }));
    document.body.classList.add('portfolio-reports-v2');
  }

  componentWillUnmount() {
    document.body.classList.remove('portfolio-reports-v2');
  }

  render() {
    if (!this.state.reportData) return null;
    return (
      <ErrorBoundary>
        <PortfolioContextProvider value={this.state.reportData}>
          <Content />
        </PortfolioContextProvider>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  portfolioReport: state.global.portfolioReport,
});

const mapDispatchToProps = {
  setGlobalState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioReportV2);
