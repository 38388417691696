import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Switch, Router } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { Security } from '@okta/okta-react'
// import { toRelativeUrl } from '@okta/okta-auth-js'
// import { OktaAuth } from '@okta/okta-auth-js';
import { oktaAuth, oktaConfig, updateRedirectURI } from 'oktaAuth'

import { Helmet } from 'react-helmet'
import $ from 'jquery';
import qs from 'query-string'

import * as AuthUtils from './common/auth-utils';
import { setGlobalState } from './actions/global';
import { setRegisterState } from './actions/register';
import { getTredingSearches } from './actions/trendingsearches';
import { logout, setOktaAuthLoader, oktaAuthLoginRequest } from './actions/auth';
import { subscriptionPlan } from './actions/profile';
import { createLoadingSelector } from './selectors/loading-selector';
import DoNotLeave from './common/DoNotLeave/DoNotLeave';
import IndividualInvestPop from './common/IndividualInvestPop/IndividualInvestPop';
import FinancialProfPop from './common/FinancialProfPop/FinancialProfPop';

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-pro/css/all.min.css'
import 'ion-rangeslider/css/ion.rangeSlider.css'
import 'ion-rangeslider/css/ion.rangeSlider.skinFlat.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import 'third-party/jquery-flipster/jquery.flipster.min.css'

import WebDashboardLayout from 'layouts/WebDashboard';
import { Loader } from 'components/Loader'

// import { Spinner } from './shared-components';
import ChokidarRouter from './ChokidarRouter';

import config from 'env';
import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import { setWithExpiry, getWithExpiry, addEventToAnalytics } from 'Utils';

import analyticsInit from './analytics';
// import { initRealTimePrice } from './realTimePrice/socket'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doNotLeave: false,
      individualInvest: false,
      financialProf: false,
      parsedQ: qs.parse(props.history.location.search)
    };

    updateRedirectURI(`${window.location.origin}${props.history.location.pathname}`);

    this.oktaAuth = window.OktaAuth;
    if (window.OktaAuth) {
      this.oktaAuth = new window.OktaAuth(oktaConfig.oidc);
    }

    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      console.log(_oktaAuth, originalUri)
      // props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  }

  componentWillMount = () => {
    // this.verifyWithOkta();
    const parsedQ = qs.parse(this.props.history.location.search);
    if(parsedQ['magnifiAuthToken'] && parsedQ['email'] && parsedQ['firstName'] && parsedQ['lastName'] && parsedQ['isMobileApp']){
      let obj = {};
      obj['token'] = parsedQ['magnifiAuthToken'] || '';
      obj['email'] = parsedQ['email'] || '';
      obj['client_exist'] = 'false';
      obj['user_advisor_menu'] = 'true';
      obj['profile_self_role'] = 'Individual Investor';
      obj['self_role'] = 'retail';
      obj['firstName'] = parsedQ['firstName'] || '';
      obj['lastName'] = parsedQ['lastName'] || 0;
      window.localStorage.setItem('fromMobileApp', parsedQ['isMobileApp']);
      AuthUtils.storeAuthToken(obj);
      window.location.href = this.props.history.location.pathname;
    }
    this.addGMTScript();
  }

  componentDidMount = () => {
    analyticsInit();
    // console.log(' ===== PROCESS ENV ===== ');
    // console.log(process.env);
    // console.log(' ======================= ');

    // const { loggedIn } = this.props;
    // if (loggedIn) {
    //   this.props.subscriptionPlan();
    // }
    // this.props.getTredingSearches();

    window.sessionStorage.removeItem('filterAttrs')
    window.sessionStorage.removeItem('sortAttr')
    window.sessionStorage.removeItem("rankingAttr")
    window.sessionStorage.removeItem("cardSortAttr")
    let counter = getWithExpiry("popupCounter");
    if (!counter) {
      setWithExpiry("popupCounter", 0, ((1000 * 60) * 60) * 24);
    }
    const premiumUser = window.localStorage.getItem("isPremium");
    if (!premiumUser) {
      window.localStorage.setItem("isPremium", 0);
    }
    var mouseY = 0;
    // var popupCounter = 0;

    document.addEventListener("mousemove", function (e) {
      mouseY = e.clientY;
    });

    $(document).mouseleave(() => {
      if(!this.props.showJoinModal) {
        const premiumUser = window.localStorage.getItem("isPremium");
        let popupCounter = getWithExpiry("popupCounter");
        if (!popupCounter) {
          setWithExpiry("popupCounter", 0, ((1000 * 60) * 60) * 24);
        }
        if (popupCounter && mouseY < 100 && this.checkWhiteListed() && premiumUser === "0") {
          if (popupCounter.value < 1) {
            if(this.props.loggedIn && process.env.REACT_APP_SUB_DOMAIN === 'retail'){
            }else{
              this.toggleDoNotLeave();
            }
          }
          popupCounter.value = parseInt(popupCounter.value) + 1;
          window.localStorage.setItem("popupCounter", JSON.stringify(popupCounter));
        }
      }
    });

    // initRealTimePrice()
    window.sessionStorage.setItem("magnifi_host", window.location.href);

    // NOTE: Segment Script
    // ---------------------------------------------
    this.addSegmentScript();
    // ---------------------------------------------
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.logoutSuccess !== prevProps.logoutSuccess) {
      if (this.props.logoutSuccess) {
        window.localStorage.removeItem("popupCounter");
        window.localStorage.removeItem("isPremium");
        window.location.href = REDIRECT_DOMAIN_PATH;
      }
    }
  }

  checkWhiteListed = () => {
    let whiteList = ['/', ''];
    let filtered = whiteList.filter(v => window.location.pathname === v);
    return filtered.length
  }

  addPTagScript = () => {
    const script = document.createElement("script");
    script.src = "./PtarmiganTagAnalytics.js";
    document.body.appendChild(script);
    const noscript = document.createElement("noscript");
    noscript.text = '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1816922&fmt=gif" />';
    document.body.appendChild(noscript);
  }

  addGMTScript = () => {
    // console.log("config.googleManagerTag ==> ", config.googleManagerTag);
    if (config.googleManagerTag) {
      const script = document.createElement("script");
      script.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-593L4XF');"
      // script.text = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","GTM-593L4XF");'
      document.getElementsByTagName("head")[0].appendChild(script);
      const iframe = document.createElement("iframe");
      iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-593L4XF";
      iframe.height = "0";
      iframe.width = "0";
      iframe.style = "display:none;visibility:hidden";
      document.body.appendChild(iframe);
    }
  }

  addSegmentScript = () => {
    // NOTE: Segment Script
    // Before updating script code, Make sure to use 'analytics' as analytics variable
    // ------------------------------------------------------------------------------------------
    if (config.segmentWriteKey) {
      const script = document.createElement("script");
      script.text = `
        !function() {
          var analytics = window.analytics = window.analytics || [];
          if (!analytics.initialize)
          if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
          else {
            analytics.invoked = !0;
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
            analytics.factory = function(e) {
              return function() {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics
              }
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var key = analytics.methods[e];
              analytics[key] = analytics.factory(key)
            }
            analytics.load = function(key, e) {
              var t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
              var n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e
            };
            analytics._writeKey = '${config.segmentWriteKey}';
            analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load('${config.segmentWriteKey}');
            analytics.page();
          }
        }();
      `;
      script.type = 'text/javascript';
      if (document.getElementsByTagName("head") && document.getElementsByTagName("head").length > 0) {
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    } else {
      console.log(`-- segment key is missing for ${process.env.REACT_APP_ENV} env --`);
    }
    // ------------------------------------------------------------------------------------------
  }

  gtmInit() {
    const script = document.createElement("script");
    script.src = "./gtm_ptag.js";
    document.head.appendChild(script);
  }

  toggleDoNotLeave = () => {
    if (this.state.doNotLeave) {
      addEventToAnalytics('Exit Popup Close', 'Exit Popup Close', 'EXIT_POPUP_CLOSE', {});
    } else {
      addEventToAnalytics('Exit Popup Open', 'Exit Popup Open', 'EXIT_POPUP_OPEN', {});
    }
    this.setState({
      doNotLeave: !this.state.doNotLeave
    });
  }

  toggleIndividualInvest = () => {
    if (this.state.individualInvest) {
      addEventToAnalytics('Exit Popup Individual Investor Close', 'Exit Popup Individual Investor Close', 'EXIT_POPUP_INDIVIDUAL_INVESTOR_CLOSE', {});
    } else {
      addEventToAnalytics('Exit Popup Individual Investor Open', 'Exit Popup Individual Investor Open', 'EXIT_POPUP_INDIVIDUAL_INVESTOR_OPEN', {});
    }
    this.setState({
      individualInvest: !this.state.individualInvest
    });
  }

  toggleFinancialProf = () => {
    if (this.state.financialProf) {
      addEventToAnalytics('Exit Popup Financial Professional Close', 'Exit Popup Financial Professional Close', 'EXIT_POPUP_FINANCIAL_PROFESSIONAL_CLOSE', {});
    } else {
      addEventToAnalytics('Exit Popup Financial Professional Open', 'Exit Popup Financial Professional Open', 'EXIT_POPUP_FINANCIAL_PROFESSIONAL_OPEN', {});
    }
    this.setState({
      financialProf: !this.state.financialProf
    });
  }

  render() {
    const { trendingSearches, oktaAuthLoader, isLogoutFetching, oktaAuthLoginRequest } = this.props;
    const { doNotLeave, individualInvest, financialProf, parsedQ } = this.state;
    if(parsedQ['magnifiAuthToken'] && parsedQ['email'] && parsedQ['firstName'] && parsedQ['lastName']){
      return null
    }

    // console.log('oktaAuth -->', this.oktaAuth)

    return (
      <React.Fragment>
        <Helmet>
          {/* injecting scripts on load*/}
          {/* <script src={`https://maps.googleapis.com/maps/api/js?key=${config.googleAnalyticsCode}`}></script> */}
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.googleTaManagerCode}`}></script>
          {/*<script type="text/javascript">{analyticsInit()}</script>*/}
          {config.PtarmiganTag === true && <script type="text/javascript">{this.gtmInit()}</script>}
        </Helmet>
        {config.PtarmiganTag === true && this.addPTagScript()}
        {config.PtarmiganTag === true && <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M877DG5" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>}
        {(oktaAuthLoader || isLogoutFetching) && <Loader loading />}
        <Security {...this.props} oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
          <ConnectedRouter history={this.props.history}>
            <Router {...this.props}>
              <>
              <DoNotLeave
                open={doNotLeave}
                cancel={this.toggleDoNotLeave}
                openIndidualPop={this.toggleIndividualInvest}
                openFinacPop={this.toggleFinancialProf}
                trendingSearches={trendingSearches}
                history={this.props.history}
                />
              <IndividualInvestPop
                open={individualInvest}
                cancel={this.toggleIndividualInvest}
                history={this.props.history}
                />
              <FinancialProfPop
                open={financialProf}
                cancel={this.toggleFinancialProf}
                history={this.props.history}
                />
              <Switch>
                <ChokidarRouter
                  Layout={WebDashboardLayout}
                  oktaAuthLoader={oktaAuthLoader}
                  setOktaAuthLoader={setOktaAuthLoader}
                  oktaAuthLoginRequest={oktaAuthLoginRequest}
                />
              </Switch>
              </>
            </Router>
          </ConnectedRouter>
        </Security>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth: { logoutSuccess, loggedIn, oktaAuthLoader }, global: { showJoinModal }, loading, trendingSearches, profile }) => ({
  loggedIn,
  showJoinModal,
  trendingSearches,
  logoutSuccess: logoutSuccess,
  premiumUser: profile.user && profile.user.premiumUser,
  isLogoutFetching: createLoadingSelector(['LOGOUT'])({ loading }),
  oktaAuthLoader: createLoadingSelector(['AUTH', 'OKTA_AUTH_LOGIN'])({ loading }) || oktaAuthLoader,
})

const mapDispatchToProps = {
  setGlobalState,
  logout,
  setRegisterState,
  getTredingSearches,
  subscriptionPlan,
  setOktaAuthLoader,
  oktaAuthLoginRequest,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
