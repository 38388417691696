import React from 'react';

import PortfolioName from '../Shared/PortfolioName';
import PortfolioLogo from '../Shared/PortfolioLogo';
import FeeRiskReturn from '../Shared/FeeRiskReturn';
import ModelRegionsChart from '../Shared/ModelRegionsChart';

const PortfolioSection = (props) => {

  if (props.data && (
    (props.data.FRS && typeof props.data.FRS._defaultFRR === 'undefined') ||
    (props.data.FRS && typeof props.data.FRS._originalQueryVar === 'undefined') ||
    (props.data.FRS && typeof props.data.FRS.query === 'undefined')
  )) {
    props.data.data = {
      ...props.data.data,
      ...props.data.FRS,
    }
  }

  let {
    data,
    data: {
      FRS,
      portfolioName,
      currentPortfolioNameInitial: initial,
      colors,
      sponsor,
      category,
      centerDisplayText,
      _defaultFRR: defaultFRR,
      _originalQueryVar: originalQueryVar,
      data: {
        // custom_replace_pf_name,
        custom_replace_type
      }
    },
    type,
    query,
  } = props;

  console.log(type, { data });

  if (type === 'current' &&
    data.FRS &&
    data.FRS.cardInfo &&
    data.FRS.cardInfo.name &&
    portfolioName !== data.FRS.cardInfo.name
  ) {
    portfolioName = data.FRS.cardInfo.name;
  }

  // if (data.FRS && typeof data.FRS._defaultFRR === 'undefined') {
  //   data.FRS._defaultFRR = defaultFRR;
  // }
  // if (data.FRS && typeof data.FRS._originalQueryVar === 'undefined') {
  //   data.FRS._originalQueryVar = originalQueryVar;
  // }
  // if (data.FRS && typeof data.FRS.query === 'undefined') {
  //   data.FRS.query = query;
  // }

  return (
    <div
      className="pf-section-wrapper"
      style={{
        minWidth: props.minWidth ? props.minWidth : '100%',
        width: props.width ? props.width : '100%',
      }}
    >
      {props.title && <div className="pf-heading">
        <h5>{props.title}</h5>
      </div>}
      <div
        className={
          `pf-wrapper ${type === 'current' ? 'grey-bg' :
            type === 'enhanced' ? 'highlight-bg' : 'white-bg'}`
        }
      >
        <div
          className={
            `pf-section-inner ${type !== 'current' ? 'white-bg' : ''}`
          }
         >
          <div className="pf-details">
            {(type === 'current'||custom_replace_type==='user') ?
              <PortfolioName
                name={portfolioName}
                initial={initial}
              /> :
              <PortfolioLogo
                name={portfolioName}
                sponsor={sponsor}
                category={category}
              />
            }
            <FeeRiskReturn
              type={type}
              data={data.FRS || {}}
            />
          </div>
          <div className="allocation-details">
            <ModelRegionsChart
              width={120}
              height={120}
              name="Allocations"
              card={data.data}
              colors={colors}
              type={type}
              overlap={centerDisplayText}
            />
          </div>
        </div>
      </div>
    </div>
  )
};
export default PortfolioSection;
