import React from 'react'
import { Row, Col } from 'reactstrap'

// import { haveHTMLData } from 'Utils';
import { Disclosures } from './Disclosures'

import {
  Coverage_Logo,
  Coverage_poweredByLogo,
  ProposalCoverImage,
  CoverFooterIcon
} from '../../../../../common/constant'
// import MdlEnhancerContext from '../Context/MdlEnhancerContext';

const MagnifiLogo = Coverage_Logo
const poweredByLogo = Coverage_poweredByLogo

const CoverPage = props => {
  const { createdDate, disclosures, portfolioName } = props
  // let { logo } = useContext(MdlEnhancerContext);
  return (
    <React.Fragment>
      <div className="page">
        <div className="content cover">
          <div className="pr-v2-cover-page">
            <div>
              <img
                className={'cover-logo'}
                src={ProposalCoverImage}
                alt=""
                width={120}
                height={120}
              />
              {/* <div className={'title-name-logo'}>
                <h1>Enhancer </h1>
                <img style={{ height: `30px` }} src={poweredByLogo} alt="" />
              </div> */}
              <div>
                <h1 className="title">Enhancer Report</h1>
                {createdDate && (
                  <p className="date">Data as of {createdDate}</p>
                )}
                <h2 className="paragraph" style={{ fontWeight: '600' }}>
                  {portfolioName}
                </h2>
              </div>
              <Row>
                <Col>
                  <p className="paragraph">
                    TIFIN Wealth is changing the way we shop for investments.
                    The world's first semantic search engine for finance helps
                    discover and compare investment products such as ETFs,
                    Mutual funds, etc.
                  </p>
                  <p className="paragraph">
                    This proposal is designed to select and compare available
                    thematic investment options to determine which options meet
                    your criteria and may help to enhance your portfolio.
                  </p>
                  <Disclosures
                    createdDate={createdDate}
                    disclosure={disclosures}
                  />
                </Col>
              </Row>
            </div>
            {/*
             <div className="glossary-footer text-justify">
               <span>This information is not personalized investment or tax advice or an investment recommendation from Magnifi, and is intended for use only by a third party financial advisor, with other information, as a resource to help build a portfolio or as an input in the development of investment advice for its own clients.</span><br />
               <span>Such financial advisors are responsible for making their own independent judgment as to how to use this information. Magnifi does not have investment discretion over the investment recommendations by such advisors. Performance of any account or portfolio derived from this information may vary materially from the performance shown herein.</span><br />
               <span>There is no guarantee that any investment strategy illustrated will be successful or achieve any particular level of results. Please review the disclosures at the end of this document and consult your financial advisor for more information.</span><br />
               <span>Magnifi LLC is an SEC registered investment adviser relying on rule 203A-2(e) for registration. Reference to registration with the Securities and Exchange Commission (“SEC”) does not imply that the SEC has endorsed or approved the qualifications of the firm to provide any advisory services described or that the firm has attained a level of skill or training.</span>
             </div>
            */}
          </div>
        </div>
        <div className="cover-footer">
          <div className="glossary-footer text-justify">
            <div style={{ width: '100%' }}>
              {props.logo && (
                <img
                  className={'cover-logo'}
                  style={{ height: `75px` }}
                  src={props.logo}
                  alt=""
                />
              )}
            </div>

            <hr style={{ border: '1px solid #E4E7EC', margin: '20px  0' }} />

            <p className="pr-v2-medium-paragraph">
              There is no guarantee that any investment strategy illustrated
              will be successful or achieve any particular level of results.
              Please review the disclosures at the end of this document and
              consult your financial advisor for more information.
            </p>
            <br />
            {/* <span className="pr-v2-medium-paragraph">Magnifi LLC is an SEC registered investment adviser relying on rule 203A-2(e) for registration. Reference to registration with the Securities and Exchange Commission (“SEC”) does not imply that the SEC has endorsed or approved the qualifications of the firm to provide any advisory services described or that the firm has attained a level of skill or training.</span> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default CoverPage
