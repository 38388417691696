import React, { Component } from 'react'
import { createAPIInstance } from 'apis/api'
import RecommendationReport from './RecommendationReport/RecommendationReport'
import ModelEnhancementReport from './ModelEnhancementReport'

export const fetchReportData = async requestQuery => {
  return createAPIInstance()
    .get('/user/get-compare-reports', { params: { id: requestQuery } })
    .then(response => response.data)
}

class EnhancerInterimReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportData: ''
    }
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const rid = query.get('id')
    if (!rid) return
    const reportData = await fetchReportData(rid).then(
      reportData =>
        reportData.message !== 'No Data Present' &&
        JSON.parse(reportData[0].json)
    )
    console.log("reportData -> ", JSON.parse(JSON.stringify(reportData)));
    this.setState({ reportData })
  }

  render() {
    const { reportData } = this.state
    return (
      <React.Fragment>
        {reportData && <ShowReport data={this.state.reportData} />}
      </React.Fragment>
    )
  }
}

export default EnhancerInterimReport

const ShowReport = ({ data }) => {
  const { searchTypeMode } = data;
  return searchTypeMode === 'FUNDS' ? (
    <RecommendationReport data={data} />
  ) : (
    <ModelEnhancementReport data={data} />
  )
}
